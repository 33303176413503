<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Inactive Accounts</h1>
      <div class="flex justify-end items-end">
        <button
          class="bg-blue-800 py-3 px-6 rounded-md text-white"
          @click="downloadCSV"
        >
          Download CSV
        </button>
      </div>
      <div class="flex items-center">
        <select
          class="inline-block p-3 my-2 mr-2 ring-1 w-32 bg-transparent ring-blue-800 rounded-sm w-64 text-sm"
          name=""
          id=""
          v-model="searchBy"
          required
        >
          <option value="">Search by</option>
          <option value="account_number">Account number</option>
          <option value="business_name">Business name</option>
        </select>
        <input
          type="text"
          class="inline-block px-2 ring-1 outline-none ring-blue-800 rounded-sm w-64 text-sm py-3"
          name=""
          placeholder="Search account number or business name"
          id=""
          v-model="search"
        />
        <button
          class="bg-blue-800 py-3 ml-5 mr-3 px-6 rounded-md text-sm text-white"
          @click="searchRecords"
        >
          Search Records
        </button>
        <button
          class="bg-green-500 text-white p-3 text-sm mr-3 rounded-md"
          @click="downloadSearchedInactiveAccounts"
        >
          Download
        </button>
        <button
          class="bg-red-500 text-white p-3 text-sm rounded-md"
          @click="clearSearchFunc"
        >
          Clear Search
        </button>
      </div>
      <div class="mt-14">
        <select
          class="inline-block p-3 my-2 ring-1 w-32 bg-transparent ring-blue-800 rounded-sm w-64 text-sm"
          name=""
          id=""
          v-model="filterInactivityByDays"
          required
        >
          <option value="">Select no of inactive days</option>
          <option value="30">30 days inactive</option>
          <option value="45">45 days inactive</option>
          <option value="60">60 days inactive</option>
          <option value="00">Custom</option>
        </select>
        <div class="inline-block" v-if="filterInactivityByDays === '00'">
          <input
            type="number"
            class="inline-block px-2 ring-1 outline-none ring-blue-800 rounded-sm ml-2 w-64 text-sm py-3"
            name=""
            placeholder="Enter number of inactive days"
            id=""
            v-model="noOfInactiveDays"
          />
        </div>
        <div class="inline-block">
          <button
            class="inline-block drop-shadow-2xl bg-blue-800 ring-1 text-white mx-2 p-3 px-4 text-sm mt-4 rounded-sm"
            @click="handleFilterFunc"
          >
            Filter
          </button>
        </div>

        <div class="inline-block">
          <button
            class="inline-block drop-shadow-2xl bg-transparent ring-1 text-dark p-3 text-sm mt-4 rounded-sm ml-2 ring-green-500"
            @click="downloadFilteredInactiveAccount"
          >
            Download
          </button>
        </div>
        <div class="inline-block">
          <button
            class="inline-block drop-shadow-2xl bg-transparent ring-1 text-dark p-3 text-sm mt-4 rounded-sm ml-2 ring-red-500"
            @click="clearFilterFunc"
          >
            Clear Filter
          </button>
        </div>
        <div style="height: 600px; overflow: auto">
          <table class="shadow-lg bg-white w-full relative border-collapse">
            <tr>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
                v-if="check_auth"
              >
                <input type="checkbox" v-model="selectAll" />
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                S/N
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Account Name
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Store Name
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Business Name
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Account Number
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Inactive days
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
                v-if="check_auth"
              >
                Action
              </th>
            </tr>
            <tr
              class="text-xs"
              v-for="(account, i) in filterAccounts"
              :key="account._id"
            >
              <td v-if="check_auth">
                <input type="checkbox" :value="account" v-model="selected" />
              </td>
              <td>{{ i + 1 }}</td>
              <td>{{ account.account_name || "N/A" | truncate(30, "...") }}</td>
              <td>{{ account.store_name || "N/A" | truncate(30, "...") }}</td>
              <td>
                {{ account.business_name || "N/A" | truncate(30, "...") }}
              </td>
              <td>{{ account.account_number || "N/A" }}</td>
              <td>{{ account.inactive_period + " days" || "N/A" }}</td>
              <td v-if="check_auth">
                <button
                  class="text-left rounded-md text-red-500 font-bold"
                  @click="deactivateSingle(account)"
                >
                  Deactivate
                </button>
              </td>
            </tr>
          </table>
        </div>
        <div
          class="mt-3 flex"
          v-if="filterAccounts && filterAccounts.length > 0"
        >
          <button
            @click="prevPage(currentPage)"
            :disabled="currentPage === 1 ? isDisabled : !isDisabled"
            class="inline-block uppercase shadow hover:bg-blue-600 focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-8 rounded"
            :class="
              currentPage === 1
                ? 'bg-blue-50 text-black'
                : 'bg-blue-900 text-white'
            "
          >
            &laquo; Prev
          </button>
          <ul class="inline-block mx-auto">
            <li
              class="inline-block px-3 py-2 font-bold cursor-pointer"
              @click="handlePagination(index)"
              v-for="(index, i) in pagination"
              :key="i"
              :class="
                index === currentPage
                  ? 'bg-blue-800 text-white rounded-md'
                  : 'text-blue-800'
              "
            >
              {{ index }}
            </li>
          </ul>
          <button
            @click="nextPage(currentPage)"
            class="float-right inline-block uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
            :disabled="
              filterAccounts && filterAccounts.length > 99 ? false : true
            "
          >
            Next &raquo;
          </button>
        </div>
        <div class="mt-10 mb-30">
          <button
            class="bg-red-600 block text-white text-xs py-6 px-6 rounded-sm float-right text-black mb-3 font-semibold"
            @click="deactivateSelected"
          >
            Deactivate Account ({{ selected.length }})
          </button>
        </div>
      </div>
      <div
        v-if="deactivateAccountModal"
        class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
      >
        <div
          class="w-1/2 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 overflow-auto"
          :class="selected.length > 3 ? 'h-4/5' : 'h-3/5'"
        >
          <h4 class="font-bold text-lg">Deactivate Accounts</h4>
          <p class="text-sm">
            Merchants selected
            <span class="bg-blue-100 p-1">{{ selected.length }}</span>
          </p>
          <ul
            class="list-none overflow-auto text-xs"
            :class="selected.length > 3 ? 'h-64' : 'h-24'"
          >
            <li
              v-for="(selectedAccount, i) in selected_short"
              :key="i"
              class="py-3 border-b border-grey-900"
              style="border-bottom: solid 1px #ccc"
            >
              <p>
                Account Name:
                <span class="font-semibold">{{
                  selectedAccount.account_name || "N/A"
                }}</span>
              </p>
              <p>
                Account Number:
                <span class="font-semibold">{{
                  selectedAccount.account_number || "N/A"
                }}</span>
              </p>

              <p>
                Business Name:
                <span class="font-semibold">{{
                  selectedAccount.business_name || "N/A"
                }}</span>
              </p>
            </li>
          </ul>
          <div class="bg-blue-50 p-4 text-sm">
            You have selected {{ selected.length }} Merchant’s Account numbers
            for deactivation, Click Deactivate all button to deactivate selected
            merchants.
            <div class="flex mt-2">
              <button
                class="text-blue-800 font-bold"
                @click="handleViewAllIncactiveAccount"
              >
                View All
              </button>
              <button class="mx-2">Selected accounts</button>
            </div>
          </div>
          <div class="flex mt-5 w-full">
            <div class="block">
              <button
                class="text-blue-800 p-4"
                @click="deactivateAccountModal = false"
              >
                Cancel
              </button>
            </div>
            <div class="block float-right w-full">
              <button
                class="bg-red-500 text-white p-4 text-sm float-right"
                @click="handleDeactivateAll"
              >
                Deactivate All
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="fullInactiveModal"
        class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
      >
        <div
          class="w-3/5 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 overflow-auto"
        >
          <h4 class="font-bold text-lg">Deactivate Accounts</h4>
          <div class="bg-yellow-50 p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-exclamation-triangle-fill inline-block"
              viewBox="0 0 16 16"
            >
              <path
                d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
              />
            </svg>
            You’re about to deactivate {{ selected.length }} Inactive Merchant
            account Numbers. Action is not reversible
          </div>
          <div class="h-96 overflow-auto">
            <table class="shadow-lg bg-white w-full relative border-collapse">
              <tr>
                <th
                  class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
                  v-if="check_auth"
                >
                  <input type="checkbox" v-model="selectAll" />
                </th>
                <th
                  class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
                >
                  S/N
                </th>
                <th
                  class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
                >
                  Account Name
                </th>
                <th
                  class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
                >
                  Store Name
                </th>
                <th
                  class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
                >
                  Business Name
                </th>
                <th
                  class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
                >
                  Account Number
                </th>
                <th
                  class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
                >
                  Inactive days
                </th>
              </tr>
              <tr
                class="text-xs"
                v-for="(selectedAccountView, i) in selected"
                :key="selectedAccountView._id"
              >
                <td v-if="check_auth">
                  <input
                    type="checkbox"
                    :value="selectedAccountView"
                    v-model="selected"
                  />
                </td>
                <td>{{ i + 1 }}</td>
                <td>
                  {{
                    selectedAccountView.account_name ||
                    "N/A" | truncate(30, "...")
                  }}
                </td>
                <td>
                  {{
                    selectedAccountView.store_name ||
                    "N/A" | truncate(30, "...")
                  }}
                </td>
                <td>{{ selectedAccountView.business_name || "N/A" }}</td>
                <td>{{ selectedAccountView.account_number || "N/A" }}</td>
                <td>
                  {{ selectedAccountView.inactive_period + " days" || "N/A" }}
                </td>
              </tr>
            </table>
          </div>
          <div class="flex mt-5 w-full">
            <div class="block">
              <button
                class="text-blue-800 p-4"
                @click="fullInactiveModal = false"
              >
                Cancel
              </button>
            </div>
            <div class="block float-right w-full">
              <button
                class="bg-red-500 text-white p-4 text-sm float-right"
                @click="handleDeactivateAll"
              >
                Deactivate All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { GET_USER_BUSINESS_DATA } from "../browser-db-config/localStorage";
import authorizedUsers from "../config/authUsers";
import Pagination from "./Pagination.vue";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      search: "",
      accounts: null,
      selected: [],
      selectedWhitelist: [],
      deactivateAccountModal: false,
      filterInactivityByDays: "",
      fullInactiveModal: false,
      finalSelectedList: [],
      isDisabled: true,
      currentPage: 1,
      noOfInactiveDays: "",
      totalRecords: 0,
      totalPages: 0,
      pagination: 0,
      numberOfItemsPerPage: 120,
      searchBy: "",
    };
  },
  computed: {
    ...mapGetters(["GET_INACTIVE_ACCOUNTS"]),
    filterAccounts() {
      //let y = this.GET_DOCUMENTS.data.filter((b) => b.status === x);
      let k = this.GET_INACTIVE_ACCOUNTS.data.items.filter(
        (account) =>
          account.business_id
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          account.business_name
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          account.account_name
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          account.account_number.includes(this.search) ||
          account.store_name.toLowerCase().includes(this.search.toLowerCase())
      );
      if (this.filterInactivityByDays !== "") {
        if (this.filterInactivityByDays == "00") {
          if (this.noOfInactiveDays !== "") {
            return [...k].filter((x) => {
              return x.inactive_period >= this.noOfInactiveDays;
            });
          }
        } else {
          return [...k].filter((x) => {
            return x.inactive_period >= this.filterInactivityByDays;
          });
        }
      }
      return k;
    },
    selectAll: {
      get: function () {
        return this.GET_INACTIVE_ACCOUNTS.data.items
          ? this.selected.length == this.GET_INACTIVE_ACCOUNTS.data.items.length
          : false;
      },
      set: function (value) {
        var selected = [];
        if (value) {
          if (this.GET_INACTIVE_ACCOUNTS.data.items !== null) {
            this.GET_INACTIVE_ACCOUNTS.data.items.forEach(function (account) {
              selected.push(account);
            });
          }
        }
        this.selected = selected;
        console.log(this.selected);
      },
    },
    check_auth() {
      const userEmail = GET_USER_BUSINESS_DATA().email;
      console.log(userEmail);
      return authorizedUsers.includes(userEmail);
    },
    selected_short() {
      return this.selected.slice(0, 5);
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async mounted() {},
  async created() {
    await this.fetchInactiveAccounts();
  },
  methods: {
    async fetchInactiveAccounts() {
      this.isLoading = true;
      try {
        let response = await this.$store.dispatch("FETCH_INACTIVE_ACCOUNTS");
        if (response.status) {
          this.isLoading = false;
          this.totalRecords = response.data.totalRecords;
          this.totalPages = response.data.totalPages;
          this.pagination = Math.ceil(
            response.data.totalRecords / this.numberOfItemsPerPage
          );
          // console.log(this.pagination);
        }
      } catch (error) {}
    },
    async downloadCSV() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch("DOWNLOAD_INACTIVE_ACCOUNTS_CSV");
        this.isLoading = false;
        if (res.status == true) {
          window.open(res.data, "_blank");
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
    deactivateSelected() {
      this.deactivateAccountModal = true;
    },
    deactivateSingle(account) {
      this.selected = [];
      this.selected.push(account);
      this.deactivateAccountModal = true;
    },
    handleViewAllIncactiveAccount() {
      this.fullInactiveModal = true;
      this.deactivateAccountModal = false;
    },
    async searchRecords() {
      this.isLoading = true;
      let search = this.search;
      let searchBy = this.searchBy;

      if (search !== "" && searchBy !== "") {
        try {
          let res = await this.$store.dispatch("SEARCH_INACTIVE_ACCOUNTS", {
            search,
            searchBy,
          });
          if (res.status) {
            this.isLoading = false;
            this.totalRecords = res.data.totalRecords;
            this.totalPages = res.data.totalPages;
            this.pagination = Math.ceil(
              res.data.totalRecords / this.numberOfItemsPerPage
            );
            if (res.data.items === null) {
              alert(`Sorry, we couldn't find ${search}`);
              this.fetchInactiveAccounts();
              this.search = "";
              this.searchBy = "";
            }
          }
          this.isLoading = false;
        } catch (error) {}
      }
      this.isLoading = false;
    },
    async handleDeactivateAll() {
      if (this.selected.length > 0) {
        let res = confirm("Your action is final, do you wish to continue?");
        if (res === true) {
          this.isLoading = true;
          this.selected.map((el) => {
            this.finalSelectedList.push(el._id);
          });
          try {
            let res = await this.$store.dispatch(
              "DEACTIVATE_INACTIVE_ACCOUNTS",
              this.finalSelectedList
            );
            this.isLoading = false;
            if (res.status == true) {
              alert("Deactivation was successful");
              this.fullInactiveModal = false;
              this.deactivateAccountModal = false;
              this.selected = [];
              this.fetchInactiveAccounts();
            } else {
              alert(
                res.error ||
                  res.message ||
                  "Error: Please check network or contact admin."
              );
            }
          } catch (error) {
            console.log(error);
            this.isLoading = false;
          }
          this.isLoading = false;
        }
      }
    },
    clearFilterFunc() {
      this.filterInactivityByDays = "";
      this.noOfInactiveDays = "";
      this.fetchInactiveAccounts();
    },
    clearSearchFunc() {
      this.search = "";
      this.fetchInactiveAccounts();
    },
    deactivateLoader() {
      this.isLoading = false;
    },
    async downloadSearchedInactiveAccounts() {
      this.isLoading = true;

      let search = this.search;
      let searchBy = this.searchBy;

      if (search !== "" && searchBy !== "") {
        try {
          let res = await this.$store.dispatch(
            "DOWNLOAD_SEARCHED_INACTIVE_ACCOUNTS",
            {
              search,
              searchBy,
            }
          );
          if (res.status) {
            this.isLoading = false;
            this.totalRecords = res.data.totalRecords;
            this.totalPages = res.data.totalPages;
            this.pagination = Math.ceil(
              res.data.totalRecords / this.numberOfItemsPerPage
            );
            window.open(res.data, "_blank");
            // this.deactivateLoader();
            this.fetchInactiveAccounts();
          }
          this.isLoading = false;
        } catch (error) {}
      }
      this.isLoading = false;
    },
    async downloadFilteredInactiveAccount() {
      this.isLoading = true;
      let filter =
        this.filterInactivityByDays == "00"
          ? this.noOfInactiveDays
          : this.filterInactivityByDays;
      if (filter !== "") {
        try {
          let res = await this.$store.dispatch(
            "DOWNLOAD_FETCH_FILTERED_INACTIVE_ACCOUNTS",
            filter
          );
          if (res.status) {
            this.isLoading = false;
            this.totalRecords = res.data.totalRecords;
            this.totalPages = res.data.totalPages;
            this.pagination = Math.ceil(
              res.data.totalRecords / this.numberOfItemsPerPage
            );
            window.open(res.data, "_blank");
            this.fetchInactiveAccounts();
          }
          this.isLoading = false;
        } catch (error) {}
      }
      this.isLoading = false;
    },
    async handleFilterFunc() {
      this.isLoading = true;
      let filter =
        this.filterInactivityByDays == "00"
          ? this.noOfInactiveDays
          : this.filterInactivityByDays;
      if (filter !== "") {
        try {
          let res = await this.$store.dispatch(
            "FETCH_FILTERED_INACTIVE_ACCOUNTS",
            filter
          );
          if (res.status) {
            this.isLoading = false;
            this.totalRecords = res.data.totalRecords;
            this.totalPages = res.data.totalPages;
            this.pagination = Math.ceil(
              res.data.totalRecords / this.numberOfItemsPerPage
            );
          }
          this.isLoading = false;
        } catch (error) {}
      }
      this.isLoading = false;
    },
    async handlePagination(page) {
      this.isLoading = true;
      this.currentPage = page;
      try {
        let res = await this.$store.dispatch(
          "FETCH_PAGINATED_INACTIVE_ACCOUNTS",
          page
        );
        if (res.status) {
          this.isLoading = false;
        }
        this.isLoading = false;
      } catch (error) {}
      this.isLoading = false;
    },
    async prevPage() {
      this.isLoading = true;
      let page = this.currentPage - 1;
      try {
        let res = await this.$store.dispatch(
          "FETCH_PAGINATED_INACTIVE_ACCOUNTS",
          page
        );
        if (res.status) {
          this.isLoading = false;
          this.currentPage -= 1;
        }
        this.isLoading = false;
      } catch (error) {}
      this.isLoading = false;
    },
    async nextPage() {
      this.isLoading = true;
      let page = this.currentPage + 1;
      try {
        let res = await this.$store.dispatch(
          "FETCH_PAGINATED_INACTIVE_ACCOUNTS",
          page
        );
        if (res.status) {
          this.isLoading = false;
          this.currentPage += 1;
        }
        this.isLoading = false;
      } catch (error) {}
      this.isLoading = false;
    },
  },
  components: { Pagination },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(241, 241, 241);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: #cbd2d9;
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
  position: sticky;
  top: 0;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
